/* reducers.js */
import AppInitialState from "./../../../app-state/app-initial-state";

const Reducer = function(product = AppInitialState.product, action = {}) {
	switch (action.type) {
		default:
			return product;
	}
};

export default Reducer;
