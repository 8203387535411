export const sizes = {
	SM: "sm",
	MD: "md",
};

export const variants = {
	PRIMARY: "primary",
	SECONDARY: "secondary",
	TERTIARY: "tertiary",
	TEXT: "text",
	DANGER: "danger",
	SUBTLE: "subtle",
	SUCCESS: "success",
};
