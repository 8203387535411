import React from "react";
import { Button } from "shards-react";
import styled from "styled-components";

/** Global Module Dependencies **/
import Flex from "common/elements/Flex";
import Heading from "common/elements/Heading";

const IconButton = styled(Button)`
  background: none !important;
  border: none !important;
  margin: 1rem 0 0;
  outline: none !important;
  box-shadow: none !important;
  color: rgb(61, 81, 112);
  opacity: 0.45;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
`;

const ModalHeading = styled(Heading)`
  color: rgb(61, 81, 112);
  opacity: 0.45;
  font-size: 0.7rem !important;
  margin: 1rem 0 0;
`;

const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

const StyledModal = styled.div`
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.modal};
  overflow-y: auto;

  user-select: none;
  background-color: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(5px);
  border: 1px solid #ededed29;
  border-radius: 1rem;
  box-shadow: inset 0 0 10px rgb(0 0 0 / 20%);
  filter: drop-shadow(3px 3px 3px rgba(100, 105, 90, 0.3));

  :after {
    /*content: "";*/
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    margin: 0.5rem;

    /* backdrop-filter: blur(5px); */
    border: 1px solid #ededed29;
    border-radius: 1.5rem;

    box-shadow: inset 0 0 10px rgb(0 0 0 / 20%);
    filter: drop-shadow(1px 1px 1px rgba(100, 105, 90, 0.3));
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    width: auto;
    min-width: 360px;
    max-width: 80vw;
  }
`;

const Modal = ({
				   title = undefined,
				   onDismiss = undefined,
				   onBack = undefined,
				   children = undefined,
				   hideCloseButton = false,
				   bodyPadding = "24px",
			   }) => (
	<StyledModal>
		<ModalHeader>
			<ModalTitle>
				{onBack && (
					<IconButton variant="text" onClick={onBack} area-label="go back" mr="8px">
						<i className="fa-solid fa-arrow-left" />
					</IconButton>
				)}
				<ModalHeading>{title}</ModalHeading>
			</ModalTitle>
			{!hideCloseButton && (
				<IconButton variant="text" onClick={onDismiss} aria-label="Close the dialog">
					<i className="fa-solid fa-xmark" />
				</IconButton>
			)}
		</ModalHeader>
		<Flex flexDirection="column" p={bodyPadding}>
			{children}
		</Flex>
	</StyledModal>
);

export default Modal;
