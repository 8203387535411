/* reducer.js */

/** External Module Dependencies **/
import { combineReducers } from "redux";

/** Internal Module Dependencies **/
import product from "./../components/product/reducer/reducer";

export default combineReducers({
	product,
});
