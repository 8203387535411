/* store.js */

/** External Module Dependencies **/
import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

/** Internal Module Dependencies **/
import reducer from "./../reducers/reducer";

export default function configureStore(initialState = {}) {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	return createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
}
