import styled from "styled-components";
import { flexbox, space } from "styled-system";

const Flex = styled.div`
  display: flex;
  ${flexbox}
  ${space}
`;

export default Flex;
