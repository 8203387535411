import { darkColors, lightColors } from "common/theme/colors";

export const light = {
	background: lightColors.card,
	hover: "#EEEAF4",
};

export const dark = {
	background: darkColors.card,
	hover: "#473d5d",
};
