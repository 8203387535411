import loadable from "@loadable/component";

const Product = loadable(() => import("./components/product/provider/provider"));
const Smorgasbord = loadable(() => import("./components/smorgasbord/provider/provider"));
const Stable = loadable(() => import("./components/stable/provider/provider"));

const routes = [
	{ path: "/", exact: true, name: "curved", component: Product, title: "Curved.Money (CURVED)" },
	{ path: "/curved", exact: true, name: "curved", component: Product, title: "Curved.Money (CURVED)" },
	{ path: "/smorgasbord/", exact: false, name: "smorgasbord", component: Smorgasbord, title: "Curved Smorgasbord (SMORES)" },
	{ path: "/smorgasbord/:referrer*", exact: false, name: "smorgasbord", component: Smorgasbord, title: "Curved Smorgasbord (SMORES)" },
	{ path: "/stable", exact: true, name: "stable", component: Stable, title: "Curved Stable (STABLE)" },
];

export default routes;
