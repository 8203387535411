/* index.js */

/** External Module Dependencies **/
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { ModalProvider } from "common/elements/Modal";
import { ThemeContextProvider } from "contexts/ThemeContext";

/** Global Module Dependencies **/
import * as serviceWorker from "./serviceWorker";
import AppInitialState from "./app-state/app-initial-state";

/** Internal Module Dependencies **/
import configureStore from "./store/store";
import App from "./App";

import "bootstrap";
import "bootstrap/dist/css/bootstrap-reboot.css";
import "shards-ui/dist/css/shards.css";
import "animate.css/animate.min.css";

import "./style-bootstrap.css";
import "./style-index.css";
import "./style-navigation.css";

function getLibrary(provider, connector) {
	const library = new Web3Provider(provider);
	library.pollingInterval = 12000;

	return library;
}

ReactDOM.render(
	<Provider store={configureStore(AppInitialState)}>
		<ThemeContextProvider>
			<BrowserRouter>
				<ModalProvider>
					<Web3ReactProvider getLibrary={getLibrary}>
						<App />
					</Web3ReactProvider>
				</ModalProvider>
			</BrowserRouter>
		</ThemeContextProvider>
	</Provider>,
	document.getElementById("root"),
);

serviceWorker.unregister();
