import { light as lightButton } from "common/elements/Button/theme";
import { light as lightNav } from "common/widgets/Menu/theme";
import { light as lightModal } from "common/widgets/Modal/theme";
import base from "./base";
import { lightColors } from "./colors";

const lightTheme = {
	...base,
	isDark: false,
	button: lightButton,
	colors: lightColors,
	nav: lightNav,
	modal: lightModal,
};

export default lightTheme;
