export const baseColors = {
	failure: "#ED4B9E",
	primary: "#3D516F",
	primaryBright: "#2D82FF",
	primaryDark: "#3D516F",
	secondary: "#056CF2",
	success: "#31D0AA",
	warning: "#FFB237",
};

export const brandColors = {
	binance: "#F0B90B",
};

export const lightColors = {
	...baseColors,
	...brandColors,
	background: "#FAF9FA",
	backgroundDisabled: "#E9EAEB",
	contrast: "#191326",
	invertedContrast: "#FFFFFF",
	input: "#eeeaf4",
	tertiary: "#0069D9",
	text: "#3D516F",
	textDisabled: "#9DBAFC",
	textSubtle: "#3D516F",
	borderColor: "#E9EAEB",
	card: "#FFFFFF",
	gradients: {
		bubblegum: "linear-gradient(139.73deg, #E6FDFF 0%, #F3EFFF 100%)",
	},
};

export const darkColors = {
	...baseColors,
	...brandColors,
	secondary: "#be7434",
	background: "#343135",
	backgroundDisabled: "#3c3742",
	contrast: "#FFFFFF",
	invertedContrast: "#191326",
	input: "#483f5a",
	// primaryDark: "#0098A1",
	tertiary: "#353547",
	text: "#EAE2FC",
	textDisabled: "#666171",
	textSubtle: "#c9c4d4",
	borderColor: "#524B63",
	card: "#27262c",
	gradients: {
		bubblegum: "linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)",
	},
};
